import {css} from '@emotion/react'
import {useContext, useState} from 'react'
import {Button, Dialog} from '@kensho/neo'

import KenshoLogo from '../../assets/kensho-dark-blue.svg'
import {BREAKPOINT_LARGE, BREAKPOINT_SMALL} from '../../styles/breakpoints'
import {h4Body2Css, whiteTextCss} from '../../styles/common'
import SiteAnalyticsContext from '../../providers/SiteAnalyticsContext'

import CookieConsentDialogContents, {CookieConsentOptions} from './CookieConsentDialogContents'

const bannerCss = css`
  z-index: 12;
  min-height: 400px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  padding: 60px 30px;
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 20px 30px;
  }
`

const gdprConsentCss = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1300px;
  margin: 0 auto;
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const textCss = css`
  max-width: 800px;
  margin: 0 40px 0 0;
  line-height: 36px;
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin: 0 0 30px 0;
  }
`

const buttonGroupCss = css`
  display: flex;
  gap: 10px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    flex-direction: column;
    align-items: flex-end;
  }
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: row;
    align-self: flex-end;
    width: 100%;
  }
`

const linkCss = css`
  color: white;
  text-decoration: underline;
`

const logoCss = css`
  height: 32px;
  width: 80px;
  margin: 0;
`

export default function CookieConsentPopup(): React.ReactNode {
  const [isOpen, setIsOpen] = useState(false)

  const analytics = useContext(SiteAnalyticsContext)

  return (
    <>
      {!analytics.consent.isSet && (
        <div css={bannerCss} data-testid="cookie-consent-banner">
          <div css={gdprConsentCss}>
            <p css={[h4Body2Css, textCss, whiteTextCss]}>
              Kensho uses cookies to improve user experience and site performance and to inform site
              analytics. Where required by applicable law, we will obtain your consent before we
              place any cookies on your device that are not strictly necessary for the functioning
              of our websites. By clicking “Accept All Cookies”, you agree to our use of cookies.
              Learn about our cookies and how to modify your preferences in our Cookie Notice. Visit
              our updated{' '}
              <a
                css={linkCss}
                href="https://www.spglobal.com/en/privacy/privacy-policy-english"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>{' '}
              and{' '}
              <a
                css={linkCss}
                href="https://www.spglobal.com/en/cookie-notice"
                target="_blank"
                rel="noreferrer"
              >
                Cookie Notice
              </a>
              , effective December 30, 2022.
            </p>
            <div css={buttonGroupCss}>
              <Button size="large" onClick={() => setIsOpen(true)}>
                Cookie Settings
              </Button>
              <Button
                intent="primary"
                size="large"
                onClick={() => {
                  analytics.setConsent({
                    functional: true,
                    targeting: true,
                    performance: true,
                    isSet: true,
                  })
                }}
              >
                Accept all Cookies
              </Button>
            </div>
          </div>
        </div>
      )}
      <Dialog
        backdrop="light"
        title={<img src={KenshoLogo} alt="Kensho Logo" css={logoCss} />}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <CookieConsentDialogContents
          /* This submission depends on the current grouping of analytics (boolean gdprConsentCookie and the targeting cookies) - if the set of analytics gets updated,
          we will need to update their data structure and how this submission works accordingly. */
          onClose={() => setIsOpen(false)}
          consentState={analytics.consent}
          changeConsent={(cookieUpdate: Partial<CookieConsentOptions>) => {
            analytics.setConsent({...analytics.consent, ...cookieUpdate})
          }}
        />
      </Dialog>
    </>
  )
}
