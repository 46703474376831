import {css} from '@emotion/react'
import {Button} from '@kensho/neo'

import {h4Body2Css, legalCss, hBoldCss, backgroundWhiteCss} from '../../styles/common'
import COLORS from '../../styles/colors'
import {BREAKPOINT_SMALL} from '../../styles/breakpoints'
import Link from '../Link'

import CookieOption from './CookieOption'

const menuBodyCss = css`
  overflow-y: auto;
  scrollbar-gutter: stable;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: unset;
  }
`

const menuTitleCss = css`
  margin: 0;
  margin-bottom: 10px;
`

const cookieLinkCss = css`
  color: ${COLORS.brand.light[550]};
  text-decoration: underline;
`

const subMenuTitleCss = css`
  margin-bottom: 20px;
`

const bottomBorderCss = css`
  border-bottom: 1px solid #bbb;
`

const menuFooterCss = css`
  position: sticky;
  padding: 20px 0 0;
  border-top: 1px solid #bbb;
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 15px 14px;
  }
`

export type CookieConsentOptions = {
  functional: boolean
  targeting: boolean
  performance: boolean
  isSet: boolean
}

interface CookieConsentDialogContentsProps {
  consentState: Omit<CookieConsentOptions, 'isSet'>
  changeConsent: (cookieUpdate: Partial<CookieConsentOptions>) => void
  onClose: () => void
}

export default function CookieConsentDialogContents({
  consentState: {functional, targeting, performance},
  changeConsent,
  onClose,
}: CookieConsentDialogContentsProps): React.ReactNode {
  return (
    <>
      <div css={[menuBodyCss, backgroundWhiteCss]}>
        <h3 css={[menuTitleCss, h4Body2Css, hBoldCss]}>Privacy Preference Center</h3>
        <p css={legalCss}>
          When you visit any web site, it may store or retrieve information on your browser, mostly
          in the form of cookies. This information might be about you, your preferences or your
          device and is mostly used to make the site work as you expect it to. The information does
          not usually directly identify you, but it can give you a more personalized web experience.
          Because we respect your right to privacy, you can choose not to allow some types of
          cookies. Click on the different category headings to find out more and change our default
          settings. However, blocking some types of cookies may impact your experience of the site
          and the services we are able to offer.
        </p>
        <Link
          to="https://cookiepedia.co.uk/giving-consent-to-cookies"
          css={[legalCss, cookieLinkCss]}
        >
          More information
        </Link>
        <div className="my-4">
          <Button
            intent="primary"
            onClick={() => {
              changeConsent({functional: true, targeting: true, performance: true})
            }}
          >
            Allow All
          </Button>
        </div>
        <h3 css={[subMenuTitleCss, h4Body2Css, hBoldCss]}>Manage Consent Preferences</h3>
        <div>
          <CookieOption
            title="Functional Cookies"
            expandedContent={
              <p css={legalCss}>
                These cookies enable the website to provide enhanced functionality and
                personalisation. They may be set by us or by third party providers whose services we
                have added to our pages. If you do not allow these cookies then some or all of these
                services may not function properly.
              </p>
            }
            checked={functional}
            onChange={(setCookie) => changeConsent({functional: setCookie})}
          />
          <CookieOption
            title="Targeting Cookies"
            expandedContent={
              <p css={legalCss}>
                These cookies may be set through our site by our advertising partners. They may be
                used by those companies to build a profile of your interests and show you relevant
                adverts on other sites. They do not store directly personal information, but are
                based on uniquely identifying your browser and internet device. If you do not allow
                these cookies, you will experience less targeted advertising.
              </p>
            }
            checked={targeting}
            onChange={(setCookie) => changeConsent({targeting: setCookie})}
          />
          <CookieOption
            title="Performance Cookies"
            expandedContent={
              <p css={legalCss}>
                These cookies allow us to count visits and traffic sources so we can measure and
                improve the performance of our site. They help us to know which pages are the most
                and least popular and see how visitors move around the site. All information these
                cookies collect is aggregated and therefore anonymous. If you do not allow these
                cookies we will not know when you have visited our site, and will not be able to
                monitor its performance.
              </p>
            }
            checked={performance}
            onChange={(setCookie) => changeConsent({performance: setCookie})}
          />
          <CookieOption
            title="Strictly Necessary Cookies"
            expandedContent={
              <p css={legalCss}>
                These cookies are necessary for the website to function and cannot be switched off
                in our systems. They are usually only set in response to actions made by you which
                amount to a request for services, such as setting your privacy preferences, logging
                in or filling in forms. You can set your browser to block or alert you about these
                cookies, but some parts of the site will not then work. These cookies do not store
                any personally identifiable information.
              </p>
            }
            checked
            css={bottomBorderCss}
          />
        </div>
      </div>
      <div css={[menuFooterCss, backgroundWhiteCss]}>
        <Button
          intent="primary"
          onClick={() => {
            onClose()
            changeConsent({isSet: true})
          }}
        >
          Save Settings
        </Button>
      </div>
    </>
  )
}
