import {useMemo, useState} from 'react'
import {HeadlessButton, Popover} from '@kensho/neo'

import {ConnectedUser} from '../../../providers/MultiplayerProvider'
import useClickOutside from '../../../hooks/useClickOutside'
import UserPresence from '../../../components/UserPresence'

interface UserPresenceListProps {
  connectedUsers: ConnectedUser[]
  currentUserSessionId: string | null
}

export default function UserPresenceList(props: UserPresenceListProps): React.ReactNode {
  const {connectedUsers, currentUserSessionId} = props

  const [isFollowing, setIsFollowing] = useState<string | null>(null)

  const [showAllUsers, setShowAllUsers] = useState(false)

  const currentConnectedUser = connectedUsers.find(
    (user) => user.sessionId === currentUserSessionId,
  )

  const sortedUsers = useMemo(() => {
    if (currentConnectedUser) {
      return [
        currentConnectedUser,
        ...connectedUsers.filter((user) => user.sessionId !== currentUserSessionId),
      ]
    }
    return connectedUsers
  }, [connectedUsers, currentConnectedUser, currentUserSessionId])

  // mousedown (instead of click) because closing the popover sets showAllUsers to false before this callback is called
  const clickOutsideRef = useClickOutside(
    () => {
      if (!showAllUsers) setIsFollowing(null)
    },
    {eventType: 'mousedown'},
  )

  return (
    <div className="flex gap-1" ref={clickOutsideRef}>
      {sortedUsers.slice(0, 3).map((user: ConnectedUser) => (
        <HeadlessButton
          key={user.sessionId}
          type="button"
          className="relative"
          onClick={() => {
            if (isFollowing !== user.sessionId) {
              setIsFollowing(user.sessionId)
            } else {
              setIsFollowing(null)
            }
          }}
        >
          <div className="flex items-center gap-2">
            <UserPresence user={user} isFollowing={user.sessionId === isFollowing} />
          </div>
        </HeadlessButton>
      ))}

      {sortedUsers.length > 3 && (
        <Popover
          content={
            <div className="flex flex-col gap-2 p-4">
              {sortedUsers.map((user: ConnectedUser) => (
                <HeadlessButton
                  key={user.sessionId}
                  type="button"
                  onClick={() => {
                    if (isFollowing !== user.sessionId) {
                      setIsFollowing(user.sessionId)
                    } else {
                      setIsFollowing(null)
                    }
                  }}
                >
                  <UserPresence
                    user={user}
                    isDetailed
                    isCurrentUser={currentUserSessionId === user.sessionId}
                    isFollowing={user.sessionId === isFollowing}
                    enablePopover={false}
                  />

                  {user.sessionId === currentUserSessionId && (
                    <hr className="my-1 border-t border-gray-400" />
                  )}
                </HeadlessButton>
              ))}
            </div>
          }
          isOpen={showAllUsers}
          onOpenChange={setShowAllUsers}
        >
          <HeadlessButton className="relative">
            <div className="flex h-8 w-8 items-center justify-center rounded-full border">
              {sortedUsers.length - 3}
            </div>
          </HeadlessButton>
        </Popover>
      )}
    </div>
  )
}
